<template>
    <header id="header"
            class="GSC_header header full-header transparent-header border-full-header header-size-custom sticky-top"
            :style="mainColor">
        <div id="EE_header" class="container mw-100 p-0">
            <nav class="navbar navbar-expand-xl navbar-light d-flex">
                <a class="navbar-brand" style="z-index: 5000;" href="/">
                    <img :src="logoSource" class="CE_header_logo navbar-img me-1"
                         height="40" width="200" alt="logo"
                         @load="imageLoaded"
                         :style="{ visibility: isLogoLoaded ? 'visible' : 'hidden' }">
                </a>
                <div class="d-flex align-items-center">
                    <button id="header-nav-btn" class="navbar-toggler"
                            :class="{ 'bi-x-lg': isOffcanvasOpen, 'bi-list': !isOffcanvasOpen }" type="button"
                            :disabled="isButtonDisabled"
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu"
                            aria-controls="offcanvasScrolling" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div class="offcanvas offcanvas-end" id="offcanvasMenu">
                    <div class="offcanvas-header" style="background-color:#fff;"></div>
                    <div class="offcanvas-body">
                        <ul class="navbar-nav ms-auto navbar-top align-items-center t-aa-end">
                            <li class="nav-item">
                                <a href="/" class="nav nav-link active home">HOME</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav nav-link" @click="scrollToHIW">HOW IT WORKS</a>
                            </li>
                            <li class="nav-item">
                                <a href="/#EE_advantages" class="nav nav-link">ADVANTAGES</a>
                            </li>
                            <li class="nav-item">
                                <a href="/#EE_wu" class="nav nav-link ">WHY US</a>
                            </li>
                            <li class="nav-item">
                                <a href="/#EE_faq" class="nav nav-link">FAQ</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>


<script>

export default {
    data() {
        return {
            logoSrc: siteSettings.elements.EE_header.CE_header_logo.src,
            mainColor: siteSettings.general.main_color.value,
            mobileMenuBgColor: siteSettings.general.mobile_menu_bg_color.value,
            mobileMenuColor: siteSettings.general.mobile_menu_color.value,
            linkColorHover: siteSettings.elements.EE_header.CE_nav_link_hover.color,
            linkColorScroll: siteSettings.elements.EE_header.CE_nav_link_scroll.color,
            isOffcanvasOpen: false,
            currentSectionId: null,
            isButtonDisabled: false,
            isLogoLoaded: false
        }
    },
    computed: {
        logoSource() {
            return this.logoSrc;
        },
        mainColor() {
            return {
                '--main-color': this.mainColor,
                '--mobile-menu-bg-color': this.mobileMenuBgColor,
                '--mobile-menu-color': this.mobileMenuColor,
                '--main-nav-link-scroll': this.linkColorScroll,
                '--main-nav-link-hover': this.linkColorHover
            };
        },
    },
    watch: {
        isOffcanvasOpen(newValue) {
            const body = document.body;
            const html = document.documentElement;
            let header = document.querySelector('.offcanvas-header');
            if (newValue) {
                header.style.display = 'flex'
                header.style.height = '80px'
                body.classList.add('scroll-lock');
                html.classList.add('scroll-lock');
            } else {
                body.classList.remove('scroll-lock');
                html.classList.remove('scroll-lock');
                header.style.height = '0px'
                header.style.display = 'none'
            }

        },
    },
    mounted() {
        window.addEventListener('sectionChange', this.handleSectionChange);
        document.getElementById("header").parentElement.style = 'display:unset;';

        const header = document.getElementById('EE_header');
        const reapplybody = document.getElementById('reapply')
        const link = document.querySelectorAll('.nav');

        if (reapplybody) {
            link.forEach(link => {
                link.style.color = '#4B4B4B';
            });
            header.style.backgroundColor = '#fff'
        }

        const offcanvasButton = this.$el.querySelector('.navbar-toggler');
        const offcanvasMenu = this.$el.querySelector('#offcanvasMenu');

        offcanvasButton.addEventListener('click', () => {
            if (!this.isButtonDisabled) {
                this.toggleOffcanvasMenu();
            }
        });

        offcanvasMenu.addEventListener('hidden.bs.offcanvas', () => {
            this.isOffcanvasOpen = false;
            window.dispatchEvent(new CustomEvent('menu-toggle', {detail: this.isOffcanvasOpen}));
        });
    },
    beforeUnmount() {
        window.removeEventListener('sectionChange', this.handleSectionChange);
        document.body.classList.remove('scroll-lock');
        document.documentElement.classList.remove('scroll-lock');
    },
    beforeDestroy() {
        window.removeEventListener('sectionChange', this.handleSectionChange);
    },
    methods: {
        imageLoaded() {
            this.isLogoLoaded = true;
        },
        toggleOffcanvasMenu() {
            this.isButtonDisabled = true;

            this.isOffcanvasOpen = !this.isOffcanvasOpen;
            window.dispatchEvent(new CustomEvent('menu-toggle', { detail: this.isOffcanvasOpen }));

            setTimeout(() => {
                this.isButtonDisabled = false;
            }, 500);
        },
        removeRefillData() {
            this.$cookies.remove('fieldsGetStarted');
            this.$cookies.remove('customerState');
            this.$cookies.remove('customerDataMain');
        },
        hideMenu() {
            if (window.innerWidth <= 991) {
                this.collapsing = true;
                setTimeout(() => this.collapsing = false, 350);
            }
        },
        handleSectionChange(event) {
            const newSectionId = event.detail;
            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach(link => {
                const href = link.getAttribute('href');
                const dataSection = link.getAttribute('data-section');

                if (href && href.substring(href.indexOf('#') + 1) === newSectionId) {
                    link.classList.add('active');
                } else if (href === '/' && newSectionId === 'slider') {
                    link.classList.add('active');
                } else if (newSectionId === 'how-it-works' && newSectionId === dataSection) {
                    link.classList.add('active');
                } else {
                    link.classList.remove('active');
                }
            });
        },
        scrollToHIW() {
            let elPosition = document.getElementById("how-it-works").offsetTop;
            let position =  elPosition - 80; //height of header

            window.scrollTo({
                top: position,
                behavior: "smooth"
            });
        }
    }
}
;
</script>

<style lang="scss" scoped>
@import "../../sass/header.scss";
</style>
